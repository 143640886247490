/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { AddAPhoto, Close } from "@mui/icons-material";
import { Box, Button, MenuItem, Modal, Select, Stack, TextField } from "@mui/material";
import { set } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";

function CameraModal(props) {
  const { open, close, capturar } = props;
  const webcamRef = useRef(null);
  const [deviceId, setDeviceId] = useState({});
  const [devices, setDevices] = useState([]);

  const handleDevices = useCallback(
    (mediaDevices) => setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
    console.log(devices);
  }, [handleDevices]);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    capturar(imageSrc);
  }, [webcamRef]);

  useEffect(() => {
    devices.length > 0 && setDeviceId(devices[0].deviceId);
  }, [devices]);

  return (
    <Modal open={open} onClose={close}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 460.2,
          height: 613.6,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
        }}
      >
        {devices.length > 1 && (
          <TextField
            select
            fullWidth
            required
            label="WebCam"
            name="webcam"
            value={deviceId}
            onChange={(e) => setDeviceId(e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ height: 55, width: 460.2, display: "block", position: "fixed", zIndex: 10, backgroundColor: "background.paper" }}
            SelectProps={{
              native: false,
            }}
          >
            {devices.map((device, key) => (
              <MenuItem key={key} value={device.deviceId}>
                {device.label}
              </MenuItem>
            ))}
          </TextField>
        )}
        {devices.map((device, key) => {
          return (
            deviceId === device.deviceId && (
              <Webcam
                key={key}
                audio={false}
                imageSmoothing={true}
                screenshotFormat="image/jpeg"
                ref={webcamRef}
                width={460.2}
                height={613.6}
                videoConstraints={{
                  width: 460.2,
                  height: 613.6,
                  facingMode: "user",
                  deviceId: device.deviceId,
                }}
              />
            )
          );
        })}
        <img
          src={require("./recomendacao.png")}
          style={{
            position: "absolute",
            bottom: "10px",
            left: "10px",
            opacity: 0.4,
            width: "200px",
            height: "100px",
          }}
        />
        <Stack
          spacing={2}
          direction="row"
          sx={{ position: "absolute", bottom: "10px", right: "10px" }}
        >
          <Button
            variant="contained"
            sx={{ width: "150px", ml: "auto" }}
            onClick={capture}
          >
            <AddAPhoto sx={{ mr: 0.5 }} />
            Capturar
          </Button>
          <Button
            variant="contained"
            sx={{ width: "70px", ml: 0.5 }}
            onClick={close}
          >
            <Close sx={{ mr: 0.5 }} />
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}

export default CameraModal;
